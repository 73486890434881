import React from "react";
import { Typography } from '@material-ui/core';


// eslint-disable-next-line import/no-anonymous-default-export
export default ({
  gutterBottom = false,
  variant = "h1",
  component = "div",
  className = null,
  message = ''
}) => {
  return (
    <>
      <Typography
        gutterBottom={gutterBottom}
        variant={variant}
        component={component}
        className={className}
      >
        {message}
      </Typography>
    </>
  )
}
