import React from "react";
import { Button } from '@material-ui/core';


// eslint-disable-next-line import/no-anonymous-default-export
export default ({
  onClick,
  disabled = false,
  variant = 'outlined',
  className = null,
  color,
  children
}) => {
  return (
    <>
      <Button onClick={onClick} disabled={disabled} variant={variant} color={color} className={className}>
        {children}
      </Button>
    </>
  )
}