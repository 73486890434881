import React from 'react';

const Logo = ({
  width = 170 ,
  marginBottom = 0,
  src="/static/PakkeLogo.png",
  alt="Logo"
}) => {
  return (
    <img
      alt={alt}
      src={src}
      width={width}
      style={{
        maxWidth: "calc(50% - 15px)"
      }}
      marginBottom={marginBottom}
    />
  );
};

export default Logo;
