export const styleTemplates = (theme) => ({
  root: {
    backgroundColor: theme,
    height: "100%",
  },
  image: {
    width: "100%",
  },
  letterColor: {
    color: "#000000d9",
    fontFamily: "Poppins",
    fontWeight: 500,
  },
  alignButton: {
    float: "right",
    marginTop: "15px",
  },
  titleStyle: {
    fontWeight: "bold",
    fontFamily: "Gilroy Extra Bold",
    fontSize: "26px",
    color: "#293769",
    lineHeight: "30px",
    marginBottom: "15px",
  },
  button: {
    backgroundColor: "#ff0801",
    fontFamily: "Gilroy Extra Bold",
    color: "#fafafa",
    "&:hover": {
      background: "#ff7570",
    },
  },
  parentCard: {
    background: "white",
    width: "475px",
    maxWidth: "100%",
    padding: "25px 30px",
    borderRadius: "15px",
    boxShadow: "0 2px 13px #29376914",
    marginBottom: "25vh",
  },
});
