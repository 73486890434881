import React from "react";
import { Box, Card, Collapse } from '@material-ui/core';
import { Alert } from "@material-ui/lab";


// eslint-disable-next-line import/no-anonymous-default-export
export default ({ openAlert, title, textAlert, severity = 'severity' }) => {
  return (
    <>
      <Box style={{width: "100%", maxWidth: "570px"}}>
        <Collapse in={openAlert}>
          <Alert severity={severity}>
            <strong>{title}</strong> {textAlert}
          </Alert>
        </Collapse>
      </Box>
    </>
  )
}