import React from "react";
import {
  makeStyles,
  Box,
  // CircularProgress,
  Collapse,
  Grid,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Page from "../../Page";
import CircularProgress from "../../molecules/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "25vh",
  },
}));

const TemplateParameters = ({
  openAlert,
  alertTitle,
  textAlert,
  alertType,
}) => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="TemplateParameters">
      {openAlert ? (
        <div
          style={{
            display: "flex",
            direction: "row",
            justifyContent: "center",
          }}
        >
          <Box sx={{ width: "100%", maxWidth: "500px", marginTop: "20px" }}>
            <Collapse in={openAlert}>
              <Alert severity={alertType}>
                <strong>{alertTitle}</strong> {textAlert}
              </Alert>
            </Collapse>
          </Box>
        </div>
      ) : (
        <Box
          sx={{ display: "flex" }}
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            marginTop: "-50px",
            marginLeft: "-100px",
          }}
        >
          <CircularProgress size={80} />
        </Box>
      )}
    </Page>
  );
};

export default TemplateParameters;
