import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { clientServices } from "../../services/clientServices";
import TemplateParameters from "../../components/templates/TempleteParameters";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundOrigin: "center",
    backgroundPosition: "center",
    padding: "20px",
    backgroundRepeat: "no-repeat",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "fit-content",
    minHeight: "100%",
    backgroundSize: "cover",
    backgroundImage: `url(https://www.pakke.com/assets/img/bg-homegood_edited_edited.2ee46347.webp)`,
  },
}));

const Parameters = () => {
  const [openAlert, setOpenAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState(false);
  const [textAlert, setTextAlert] = useState("");
  const [alertType, setAlertType] = useState("");
  const query = useQuery();
  const classes = useStyles();
  const navigate = useNavigate();
  const spapi_oauth_code = query.get("spapi_oauth_code");
  const state = query.get("state");
  const selling_partner_id = query.get("selling_partner_id");

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  useEffect(() => {
    if (!spapi_oauth_code || !state || !selling_partner_id) {
      setAlertTitle("Error");
      setTextAlert("Faltan parámetros de Amazon");
      setAlertType("error");
      setOpenAlert(true);
      // setTimeout(() => {
      //   navigate("/404");
      // }, 3000)
      return;
    }

    const token = localStorage.getItem("user_token");

    clientServices
      .createConnectionAWS(spapi_oauth_code, state, selling_partner_id)
      .then((response) => {
        window.open(
          `${response.data.url}?token=${token}&message=${response.data.message}&connected=true&id_channel=${response.data.id_channel}`,
          "_self"
        );
      })
      .catch((error) => {
        console.error("Error: ", error);
        console.error("Error response: ", error.response);
        if (error.response && error.response.data.url) {
          window.open(
            `${error.response.data.url}?token=${token}&message=${error.response.data.message}&connected=false`,
            "_self"
          );
        } else {
          setAlertTitle("Error");
          setTextAlert(
            "Se generó un error al conectar la tienda, vuelve a intentarlo ó ponte en contacto con nosotros."
          );
          setAlertType("error");
          setOpenAlert(true);
          setTimeout(() => {
            navigate("/404");
          }, 5000);
          return;
        }
      });
  });

  return (
    <>
      <div className={classes.root}>
        <TemplateParameters
          openAlert={openAlert}
          alertTitle={alertTitle}
          textAlert={textAlert}
          alertType={alertType}
        />
      </div>
    </>
  );
};

export default Parameters;
