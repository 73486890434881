import React from "react";
import { Navigate } from "react-router-dom";
import MainLayout from "./layout/MainLayout";
import Authentication from "./views/authentication/Authentication";
import NotFoundView from "./views/errors/NotFoundView";
import Parameters from "./views/parameters/Parameters";
import ParametersEbay from "./views/parameters/ParametersEbay";

const routes = () => [
  {
    path: "/marketplaces",
    children: [
      { path: "ebay/authentication", element: <Authentication /> },
      { path: "amazon/authentication", element: <Authentication /> },
      { path: "walmart/authentication", element: <Authentication /> },
      { path: "aliexpress/authentication", element: <Authentication /> },
      { path: "claro/authentication", element: <Authentication /> },
    ],
  },
  {
    path: "/",
    element: <MainLayout />,
    children: [
      { path: "parameters", element: <Parameters /> },
      { path: "parameters/ebay", element: <ParametersEbay /> },
      { path: "404", element: <NotFoundView /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
];

export default routes;
