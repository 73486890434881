import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import Typography from "../../molecules/Typography";
import Logo from "../../Logo";
import { clientServices } from "../../../services/clientServices";
import { URL_AWS } from "../../../utils/config";
import CircularProgress from "../../molecules/CircularProgress";
import Button from "../../atoms/Button";
import { styleTemplates } from "../styles";

const useStyles = makeStyles((theme) =>
  styleTemplates(theme.palette.background.dark)
);

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ token, setOpenAlert, setTextAlert }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const goToAWS = async () => {
    setLoading(true);

    try {
      const createState = await clientServices.createStateAWS(token);
      const { state_aws } = createState.data;
      const stateInBase64 = Buffer.from(state_aws.toString()).toString(
        "base64"
      );
      const url_with_state = URL_AWS.replace("stateInBase64", stateInBase64);

      window.open(url_with_state, "_self");
    } catch (error) {
      setLoading(false);

      if (error.response && error.response.status === 401) {
        setOpenAlert(true);
        setTextAlert("El token ingresado es incorrecto, intenta nuevamente.");
      } else {
        setOpenAlert(true);
        setTextAlert("Error al generar la conexión, intenta nuevamente.");
      }
    }
  };

  return (
    <>
      <div sx={{ maxWidth: 345 }} className={classes.parentCard}>
        <div>
          <div
            style={{
              flexDirection: "row",
              justifyContent: "space-around",
              alignItems: "center",
              display: "flex",
              margin: "10px 0 20px 0",
            }}
          >
            <Logo />

            <Logo src="https://marketplaces-images.s3.us-west-2.amazonaws.com/Amazon/Logo_amazon.svg" />
          </div>
          <Typography
            gutterBottom={true}
            variant="body1"
            component={"div"}
            className={classes.titleStyle}
            message={"Conecta tu tienda Amazon"}
          />
          <Typography
            gutterBottom={true}
            variant="body1"
            className={classes.letterColor}
            message={"1. Serás redireccionado a la tienda de Amazon."}
          />
          <Typography
            gutterBottom={true}
            variant="body1"
            className={classes.letterColor}
            message={"2. Inicia sesión en Amazon."}
          />
          <Typography
            gutterBottom={true}
            variant="body1"
            className={classes.letterColor}
            message={"3. Lee cuidadosamente las instrucciones."}
          />
          <Typography
            gutterBottom={true}
            variant="body1"
            className={classes.letterColor}
            message={
              "4. Acepta que Pakke pueda tener acceso a tu cuenta Amazon."
            }
          />
        </div>

        <div className={classes.alignButton}>
          <Button
            onClick={goToAWS}
            disabled={loading}
            variant="outlined"
            color="error"
            className={classes.button}
          >
            {loading && <CircularProgress size={14} />}
            {!loading && "Conectar mi tienda"}
          </Button>
        </div>
      </div>
    </>
  );
};
