import React, { useState } from "react";
import { Grid, makeStyles, TextField } from "@material-ui/core";
import Typography from "../../molecules/Typography";
import Logo from "../../Logo";
import { clientServices } from "../../../services/clientServices";
import CircularProgress from "../../molecules/CircularProgress";
import Button from "../../atoms/Button";
import { styleTemplates } from "../styles";

const useStyles = makeStyles((theme) => ({
  ...styleTemplates(theme.palette.background.dark),
  widthText: {
    width: "100%",
  },
}));

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ token, id_channel, setOpenAlert, setTextAlert }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [claroSecretKey, setErrorPrivateSecret] = useState(false);
  const [claroPublicKey, setErrorPublicSecret] = useState(false);
  const [claroPrivate, setClaroPrivateKey] = useState("");
  const [claroPublic, setClaroPublicKey] = useState("");

  const saveCredentialsOfClaro = async () => {
    if (!claroSecretKey || !claroPublicKey) {
      setErrorPrivateSecret(true);
      setErrorPublicSecret(true);
      return;
    }

    setLoading(true);

    clientServices
      .createConnectionClaro(claroPrivate, claroPublic, token, id_channel)
      .then((response) => {
        window.open(
          `${response.data.url}?token=${token}&message=${response.data.message}&connected=true&id_channel=${response.data.id_channel}`,
          "_self"
        );
      })
      .catch((error) => {
        setLoading(false);

        if (error.response) {
          if (error.response.status === 401) {
            setOpenAlert(true);
            setTextAlert(
              "El token ingresado es incorrecto, intenta nuevamente."
            );
          } else if (error.response.data.url) {
            window.open(
              `${error.response.data.url}?token=${token}&message=${error.response.data.message}&connected=false`,
              "_self"
            );
          }
        }

        setTextAlert(
          "Se generó un error al conectar la tienda, vuelve a intentarlo ó ponte en contacto con nosotros."
        );

        setOpenAlert(true);
        return;
      });
  };

  return (
    <>
      <div sx={{ maxWidth: 345 }} className={classes.parentCard}>
        <div
          style={{
            marginBottom: "15px",
          }}
        >
          <div
            style={{
              flexDirection: "row",
              justifyContent: "space-around",
              alignItems: "center",
              display: "flex",
              margin: "10px 0 20px 0",
            }}
          >
            <Logo />

            <Logo src="https://marketplaces-images.s3.us-west-2.amazonaws.com/ClaroShop/claro-shop-logo.png" />
          </div>
          <Typography
            gutterBottom={true}
            variant="body1"
            component={"div"}
            className={classes.titleStyle}
            message={"Conecta tu tienda Claro Shop"}
          />
          <Typography
            gutterBottom={true}
            variant="body1"
            className={classes.letterColor}
            message={"Ingrese los siguientes datos."}
          />
          <Grid container spacing={2} style={{ marginTop: "5px" }}>
            <Grid item xs={12}>
              <div>
                <TextField
                  error={claroSecretKey && !claroPrivate}
                  helperText={
                    claroSecretKey && !claroPrivate
                      ? "La private key es requerida"
                      : null
                  }
                  label="Private Key"
                  required
                  className={classes.widthText}
                  onChange={(e) => setClaroPrivateKey(e.target.value)}
                  value={claroPrivate}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div>
                <TextField
                  error={claroPublicKey && !claroPublic}
                  label="Prublic key"
                  helperText={
                    claroSecretKey && !claroPublic
                      ? "La Public key es requerida"
                      : null
                  }
                  required
                  className={classes.widthText}
                  onChange={(e) => setClaroPublicKey(e.target.value)}
                  value={claroPublic}
                />
              </div>
            </Grid>
          </Grid>
        </div>

        <div className={classes.alignButton}>
          <Button
            onClick={saveCredentialsOfClaro}
            disabled={loading}
            variant="outlined"
            className={classes.button}
          >
            {loading && <CircularProgress size={14} />}
            {!loading && "Conectar mi tienda"}
          </Button>
        </div>
      </div>
    </>
  );
};
