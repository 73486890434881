import React, { useEffect, useState } from "react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { makeStyles, Grid } from "@material-ui/core";
import Page from "../../components/Page";
import TemplateAmazon from "../../components/templates/TemplateAmazon";
import TemplateEbay from "../../components/templates/TemplateEbay";
import TemplateWalmart from "../../components/templates/TemplateWalmart";
import TemplateAliExpress from "../../components/templates/TemplateAliExpress";
import TemplateClaroShop from "../../components/templates/TemplateClaroShop";
import Alert from "../../components/organisms/Alert";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    backgroundOrigin: "center",
    backgroundPosition: "center",
    padding: "20px",
    backgroundRepeat: "no-repeat",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "fit-content",
    minHeight: "100%",
    backgroundSize: "cover",
    backgroundImage: `url(https://www.pakke.com/assets/img/bg-homegood_edited_edited.2ee46347.webp)`,
  },
}));

const Authentication = () => {
  const [openAlert, setOpenAlert] = useState(false);
  const [textAlert, setTextAlert] = useState("");
  const [alertTitle, setAlertTitle] = useState("Error");
  const [alertType, setAlertType] = useState("error");
  const query = useQuery();
  const path = usePathPattern();
  const navigate = useNavigate();
  const classes = useStyles();
  const token = query.get("token");
  const id_channel = query.get("id_channel");

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  function usePathPattern() {
    const { pathname } = useLocation();

    return pathname;
  }

  const renderTempate = () => {
    if (path.includes("amazon")) {
      return (
        <TemplateAmazon
          token={token}
          setOpenAlert={setOpenAlert}
          setTextAlert={setTextAlert}
        />
      );
    }
    if (path.includes("ebay")) {
      return (
        <TemplateEbay setOpenAlert={setOpenAlert} setTextAlert={setTextAlert} />
      );
    }
    if (path.includes("walmart")) {
      return (
        <TemplateWalmart
          token={token}
          id_channel={id_channel}
          setOpenAlert={setOpenAlert}
          setTextAlert={setTextAlert}
          setAlertTitle={setAlertTitle}
          setAlertType={setAlertType}
        />
      );
    }
    if (path.includes("aliexpress")) {
      return (
        <TemplateAliExpress
          setOpenAlert={setOpenAlert}
          setTextAlert={setTextAlert}
        />
      );
    }
    if (path.includes("claro")) {
      return (
        <TemplateClaroShop
          token={token}
          id_channel={id_channel}
          setOpenAlert={setOpenAlert}
          setTextAlert={setTextAlert}
        />
      );
    }
  };

  useEffect(() => {
    if (!token) {
      setOpenAlert(true);
      setTextAlert("Hace falta información - Token");
      setTimeout(() => {
        navigate("/404");
      }, 3000);
    }
    localStorage.setItem("user_token", token);
  });

  return (
    <Page className={classes.root} title="Authentication">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginBottom: "25px",
        }}
      >
        <Alert
          openAlert={openAlert}
          severity={alertType}
          title={alertTitle}
          textAlert={textAlert}
        />
      </div>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
      >
        {renderTempate()}
      </Grid>
    </Page>
  );
};

export default Authentication;
