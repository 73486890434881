import React, { useEffect, useState } from 'react';
import {
  useLocation,
  useNavigate
} from "react-router-dom";
import { clientServices } from '../../services/clientServices';
import TemplateParameters from '../../components/templates/TempleteParameters';



const ParametersEbay = () => {
  const [openAlert, setOpenAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState(false);
  const [textAlert, setTextAlert] = useState('');
  const [alertType, setAlertType] = useState('');
  const query = useQuery();
  const navigate = useNavigate();
  const code = query.get('code');
  const expires_in = query.get('expires_in');
  const id_channel = query.get('id_channel');

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  useEffect(() => {
    const token = localStorage.getItem('user_token');

    if (code && expires_in) {
      clientServices.createConnectionEbay(code, token, id_channel)
        .then(response => {
          window.open(`${response.data.url}?token=${token}&message=${response.data.message}&connected=true&id_channel=${response.data.id_channel}`, "_self");
        })
        .catch(error => {
          console.error('Error: ', error);
          console.error('Error response: ', error.response);
          if (error.response && error.response.data.url) {
            window.open(`${error.response.data.url}?token=${token}&message=${error.response.data.message}&connected=false`, "_self");
          } else {
            setAlertTitle('Error');
            setTextAlert('Se generó un error al conectar la tienda, vuelve a intentarlo ó ponte en contacto con nosotros.');
            setAlertType('error');
            setOpenAlert(true);
            setTimeout(() => {
              navigate("/404");
            }, 5000)
            return;
          }
        });
      return;
    } else {
      setAlertTitle('Error en los parámetros devueltos');
      setTextAlert('Retorno otros errores');
      setAlertType('warn');
      setOpenAlert(true);
      return;
    }
  })


  return (
    <TemplateParameters
      openAlert={openAlert}
      alertTitle={alertTitle}
      textAlert={textAlert}
      alertType={alertType}
    />
  );

}

export default ParametersEbay;