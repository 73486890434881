// STAGING
const {
  REACT_APP_URL_MK,
  REACT_APP_URL_AWS,
  REACT_APP_URL_EBAY,
  REACT_APP_URL_ALIEXPRESS,
} = process.env;

// export const URL_MK = "https://marketplaces-api.pakke.com";
// export const URL_AWS =
//   "https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.70ca5fc0-637e-47fd-870e-1bed2380b11f&state=stateInBase64&version=beta";
// export const URL_EBAY =
//   "https://auth.ebay.com/oauth2/authorize?client_id=Pakke-PakkeMK-PRD-c292f25b7-fb8f5690&response_type=code&redirect_uri=Pakke-Pakke-PakkeMK-P-adnmjdlxw&scope=https://api.ebay.com/oauth/api_scope https://api.ebay.com/oauth/api_scope/sell.marketing.readonly https://api.ebay.com/oauth/api_scope/sell.marketing https://api.ebay.com/oauth/api_scope/sell.inventory.readonly https://api.ebay.com/oauth/api_scope/sell.inventory https://api.ebay.com/oauth/api_scope/sell.account.readonly https://api.ebay.com/oauth/api_scope/sell.account https://api.ebay.com/oauth/api_scope/sell.fulfillment.readonly https://api.ebay.com/oauth/api_scope/sell.fulfillment https://api.ebay.com/oauth/api_scope/sell.analytics.readonly https://api.ebay.com/oauth/api_scope/sell.finances https://api.ebay.com/oauth/api_scope/sell.payment.dispute https://api.ebay.com/oauth/api_scope/commerce.identity.readonly https://api.ebay.com/oauth/api_scope/commerce.notification.subscription https://api.ebay.com/oauth/api_scope/commerce.notification.subscription.readonly";
// export const URL_ALI =
//   "https://oauth.aliexpress.com/authorize?response_type=code&client_id=33970091&redirect_uri=http://www.oauth.net/2/view=web&sp=ae";
export const URL_MK = REACT_APP_URL_MK;
export const URL_AWS = REACT_APP_URL_AWS;
export const URL_EBAY = REACT_APP_URL_EBAY;
export const URL_ALI = REACT_APP_URL_ALIEXPRESS;
