import React from "react";
import { CircularProgress } from '@material-ui/core';


// eslint-disable-next-line import/no-anonymous-default-export
export default ({ size = 14 }) => {
  return (
    <>
      <CircularProgress size={size} thickness={4} style={{ color: '#26376c' }} />
    </>
  )
}