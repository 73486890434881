import axios from "axios";
import { URL_MK } from "../utils/config";

const createStateAWS = async (token = "") => {
  const config = {
    method: "post",
    url: `${URL_MK}/client/helpers-aws/create-state`,
    headers: {
      Authorization: token,
    },
  };

  return await axios(config);
};

const createConnectionAWS = async (
  spapi_oauth_code,
  state_aws,
  selling_partner_id
) => {
  const data = JSON.stringify({
    spapi_oauth_code,
    state_aws,
    selling_partner_id,
  });

  const config = {
    method: "post",
    url: `${URL_MK}/client/helpers-aws/create-connection`,
    headers: {
      Authorization: " ",
      "Content-Type": "application/json",
    },
    data: data,
  };

  return await axios(config);
};

const createConnectionEbay = async (code, token, id_channel = null) => {
  const data = JSON.stringify({
    code,
  });

  const filters = id_channel ? `id_channel=${id_channel}` : null;

  const config = {
    method: "post",
    url: `${URL_MK}/client/ebay/create-connection?${filters}`,
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return await axios(config);
};

const createConnectionWalmart = async (
  walmart_client_id,
  walmart_secret_key,
  token,
  id_channel = null
) => {
  const data = JSON.stringify({
    walmart_client_id,
    walmart_secret_key,
  });

  const filters = id_channel ? `id_channel=${id_channel}` : null;

  const config = {
    method: "post",
    url: `${URL_MK}/client/walmart/create-connection?${filters}`,
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return await axios(config);
};

const createConnectionClaro = async (
  claro_private_key,
  claro_public_key,
  token,
  id_channel = null
) => {
  const data = JSON.stringify({
    claro_public_key,
    claro_private_key,
  });
  const filters = id_channel ? `id_channel=${id_channel}` : null;

  const config = {
    method: "post",
    url: `${URL_MK}/client/claroshop/create-connection?${filters}`,
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return await axios(config);
};

export const clientServices = {
  createStateAWS,
  createConnectionAWS,
  createConnectionEbay,
  createConnectionWalmart,
  createConnectionClaro,
};
